@charset "utf-8";

/* -------------------------------------------*/
/*underline*/
@mixin hover-underline($type: fade, $dir: null, $weight: 1px, $color: #000) {
  @if $dir==null {
    @if $type==fade {
      $dir: 'top';
    }

    @else if $type==slide {
      $dir: 'center';
    }
  }

  position: relative;
  display: inline-block;
  text-decoration: none;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: $weight;
    background: $color;

    @if $type==fade {
      transition: .3s;
      opacity: 0;
      visibility: hidden;

      @if $dir==bottom {
        bottom: $weight * -4;
      }

      @else {
        bottom: $weight;
      }
    }

    @else if $type==slide {
      bottom: $weight * -3;
      transform: scale(0, 1);
      transition: transform .3s;

      @if $dir==left-right or $dir==right-in-left {
        transform-origin: left top;
      }

      @else if $dir==right-left or $dir==left-in-right {
        transform-origin: right top;
      }

      @else {
        transform-origin: center top;
      }
    }
  }

  &:hover::after {
    @if $type==fade {
      bottom: $weight * -2;
      opacity: 1;
      visibility: visible;
    }

    @else if $type==slide {
      @if $dir==left-in-right {
        transform-origin: left top;
      }

      @else if $dir==right-in-left {
        transform-origin: right top;
      }

      transform: scale(1, 1);
    }
  }
}

/*arrow-fade*/
@keyframes arrow-fade {
  0% {
    transform: translateX(0);
  }

  49% {
    transform: translateX(15px);
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0px);
  }
}
