@charset "utf-8";

/* -------------------------------------------*/
$c-base:#F0F0F0;
$c-main: #6D1781;
$c-accent: #D78998;
$c-primary: #FCEB00;
$c-base-font:#333;
$c-white:#fff;
$c-black:#000;
$c-blue:#00548F;
$c-gray:#F7F7F7;
$c-d-gray:#696969;

// $c-accent-lightest: #***;
// $c-accent-lighter: #***;
// $c-accent-light: #***;
// $c-accent-dark: #***;
// $c-accent-darker: #***;
// $c-accent-darkest: #***;
