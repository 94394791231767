@charset "utf-8";
@use "../foundation"as *;

/* -------------------------------------------*/
.c-btn{
  display: inline-block;
  // width: 330px;
  width: 33rem;
  // height: 75px;
  height: 7.5rem;
  // line-height: 72px;
  line-height: 7.2rem;
  text-align: center;
  background: transparent;
  border: 1.5px solid #868080;
  border-radius: 50px;
  transition: all .5s;
  @include mq(md, max) {
    width: 140px;
    height: 32px;
    line-height: 30px;
    border: 1px solid #868080;
  }
  &:hover{
    background: #868080;
    color: #fff !important;
  }

  &-colored{
    background: #868080;
    color: #fff !important;
    &:hover{
      background: #fff;
      color: #868080 !important;
      border-color: #fff;
    }
  }

  &-transparent{
    background: transparent;
    &:hover{
      background: rgba(#fff, .5);
      color: #fff !important;
      border-color: transparent;
    }
  }

  &-white{
    background: #fff;
    color: #868080 !important;
    border-color: #fff;
    &:hover{
      background: #868080;
      color: #fff !important;
    }
  }

  &-slider{
    // font-size: 27px;
    font-size: 2.7rem;
    letter-spacing: 10px;
    border: none !important;
    @include mq(md, max) {
      font-size: 12px;
      letter-spacing: 5px;
    }
    &-01{
      background: #868080;
      color: #fff !important;
      &:hover{
        background: #fff;
        color: #868080 !important;
      }
    }
    &-02{
      background: #C9A4AA;
      color: #fff !important;
      &:hover{
        background: #fff;
        color: #868080 !important;
      }
    }
    &-03{
      background: #A39081;
      color: #fff !important;
      &:hover{
        background: #fff;
        color: #868080 !important;
      }
    }
    &-04{
      background: #A7B4AF;
      color: #fff !important;
      &:hover{
        background: #fff;
        color: #868080 !important;
      }
    }
  }

  &-mv{
    width: 20rem;
    height: 3rem;
    line-height: 3rem;
    background: #AF9886;
    color: #fff !important;
    border-radius: 0;
    border: unset;
    @include mq(md, max) {
      width: 230px;
      height: 32px;
      line-height: 32px;
      font-size: 12px;
    }
  }

  &-header-contact{
    background: #EDEDED;
    color: #808080 !important;
    border: none;
    border-radius: 0;
    width: 67.5rem;
    height: 7rem;
    line-height: 7rem;
    display: block;
    margin: 0 auto;
    @include mq(md, max) {
      width: 230px;
      height: 24px;
      line-height: 24px;
      display: block;
      margin: 0 auto;
    }
  }
}

