@charset "utf-8";
/* -------------------------------------------*/

@mixin js-fadeUp {
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  transition: opacity 500ms,
    visibility 500ms,
    transform 500ms;
}

@mixin is-fadeUp {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

@mixin js-fadeDown {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-15px);
  transition: opacity 500ms,
    visibility 500ms,
    transform 500ms;
}

@mixin is-fadeDown {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}