@charset "utf-8";
@use "../foundation"as *;

/* -------------------------------------------*/
.l-header{
  background: linear-gradient(to right,rgba(179, 159, 142, 0.3) 0%, #b39f8e 100%, #000 100%);

  &__inner{
    display: flex;
    justify-content: space-between;
    // padding: 19px 50px;
    padding: 1.9rem 5rem;
    @include mq(md, max) {
      padding: 10px;
    }
  }

  &__logo{
    display: flex;
    align-items: center;

    &--img{
      width: 6.2rem;
      @include mq(md, max) {
        width: 30px;
      }
    }

    &--title{
      letter-spacing: 0.25em;
      line-height: 1.3;
      margin-left: 14px;
      @include mq(md, max) {
        margin-left: 10px;
      }
    }
  }

  &__nav{
    margin: auto 0;
    @include mq(md, max) {
      display: none;
    }
  }

  &__list{
    display: flex;
  }

  &__list-item{
    // margin-right: 10px;
    margin-right: 1rem;
    // font-size: 24px;
    font-size: 2.2rem;
    // padding: 8px 20px;
    padding: .8rem 2rem;
    transition: all .5s;
    &:hover{
      background: #4A4A4A;
      color: #fff;
      transition: all .5s;
    }
  }

  &__tab{
    overflow: hidden;
    &--inner{
      transition: all .5s;
      // transform: translateX(100%);
      position: absolute;
      z-index: 5;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      &.active{
        transition: all .5s;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.l-header__hover{
  background: #4A4A4A;
  color: #fff;
  display: none;
  position: absolute;
  top: 10rem;
  left: 0;
  width: 100%;
  z-index: 2;
  // min-height: 440px;
  @include mq(md, max) {
    position: relative;
    top: unset;
    color: #4A4A4A;
    display: block;
    // min-height: unset;
    &:first-child,
    &:nth-child(3){
      background: #EDEDED;
    }
    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(5){
      background: #fff;
    }
  }

  &--inner{
    display: flex;
    @include mq(md, max) {
      display: block;
    }
  }

  &--left,
  &--right{
    padding: 3.5rem 4rem;
    width: 50%;
    @include mq(md, max) {
      padding: 10px 20px;
      width: 100%;
    }
  }

  &--title{
    // font-size: 24px;
    font-size: 2.2rem;
    margin-bottom: 20px;
    @include mq(md, max) {
      font-size: 7px;
      margin-bottom: 15px;
    }
    h2{
      font-weight: bold;
      border-bottom: 1px solid #fff;
      display: inline-block;
      letter-spacing: .3em;
      @include mq(md, max) {
        border-color: #4A4A4A;
      }
    }
  }

  &--list{
    &-wrap{
      display: flex;
    }
    &:first-child{
      margin-right: 50px;
      @include mq(md, max) {
        margin-right: 20px;
      }
    }
  }

  &--list-item{
    margin-bottom: 15px;
    letter-spacing: .2em;
    font-size: 1.8rem;
    @include mq(md, max) {
      margin-bottom: 5px;
    }
  }

  &.active{
    display: block;
  }
}

.l-header__hover{
  &--sns{
    padding: 40px 60px;
    @include mq(md, max) {
      padding: 10px 20px;
    }
    .l-header__hover--list-wrap{
      justify-content: space-between;
      max-width: 1735px;
      @include mq(md, max) {
        display: block;
      }
    }
    .l-header__hover--list:first-child{
      margin-right: 0;
    }
    li{
      &::before{
        content: "";
        display: inline-block;
        background-image: url(../img/index/icon-fb@2x.png);
        height: 50px;
        width: 50px;
        background-size: contain;
        vertical-align: middle;
        margin-right: 30px;
        @include mq(md, max) {
          height: 12px;
          width: 12px;
          vertical-align: sub;
          background-repeat: no-repeat;
          margin-right: 15px;
        }
      }
    }
  }
  &--blog{
    li{
      &::before{
        content: "";
        display: inline-block;
        background-image: url(../img/index/icon-fb@2x.png);
        height: 50px;
        width: 50px;
        background-size: contain;
        vertical-align: middle;
        margin-right: 30px;
        @include mq(md, max) {
          height: 12px;
          width: 12px;
          vertical-align: sub;
          background-repeat: no-repeat;
          margin-right: 15px;
        }
      }
    }
  }
}

.l-header__hover--list-item{
  &--insta{
    &::before{
        background-image: url(../img/index/icon-insta@2x.png) !important;
    }

  }
  &--tw{
    &::before{
            background-image: url(../img/index/icon-tw@2x.png) !important;
    }
  }
  &--yt{
    &::before{
            background-image: url(../img/index/icon-yt@2x.png) !important;
    }
  }
}

.hamburger{
  display: none;
}

@include mq(md, max) {
  .hamburger{
    display: block;
    width: 25px;
    height: 20px;
    position: relative;
    margin: 5px;
    span{
      background: #fff;
      width: 100%;
      height: 1px;
      position: absolute;
      transition: all .5s;
      &:first-child{
        top: 0;
        &.active{
          transform: translateX(0px) translateY(10px) rotate(45deg);
          background: #4A4A4A;
        }
      }
      &:nth-child(2){
        top: 10px;
        &.active{
          opacity: 0;
        }
      }
      &:nth-child(3){
        bottom: 0;
        &.active{
          transform: translateX(0px) translateY(-9px) rotate(-45deg);
          background: #4A4A4A;
        }
      }

    }
  }
}