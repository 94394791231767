@charset "UTF-8";
/* -------------------------------------------*/ /* -------------------------------------------*/
/* -------------------------------------------*/
:root {
  --header_height: 75px;
  --content_width: 100%;
  --side_weight: 195px;
  --side-between_space: 16px;
  --content_space--sm: 96px;
  --content_space--md: 128px;
  --sec_pd: 128px;
  --mv-news_width: 604px;
  --mv_over-height: 196px;
  --base_radius: 32px;
  --window_width: calc(100vw + calc(var(--side_weight) + var(--side-between_space)));
  --pages-left_space: 20.37vw;
  --pages-right_space: 14.8vw;
}
@media not all and (max-width: 1780px) {
  :root {
    --content_space--sm: 128px;
    --content_space--md: 180px;
    --side-between_space: 64px;
    --mv-news_width: 650px;
  }
}
@media screen and (max-width: 1560px) {
  :root {
    --content_space--sm: 4vw;
    --content_space--md: 9.2vw;
    --sec_pd: 86px;
    --mv-news_width: 38.88vw;
  }
}
@media screen and (max-width: 1440px) {
  :root {
    --content_width: 1000px;
    --header_height: 64px;
    --side_weight: 65px;
    --content_space--md: 76px;
    --mv-news_width: 82.4vw;
    --mv_over-height: 53px;
    --base_radius: 16px;
    --content-both_space: 15px;
    --window_width: 100vw;
    --pages-left_space: 19.73vw;
    --pages-right_space: 0;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --content_width: 580px;
    --side_weight: 60px;
    --content_space--md: 64px;
    --sec_pd: 32px;
  }
}

/* -------------------------------------------*/
/* -------------------------------------------*/
/* -------------------------------------------*/ /* -------------------------------------------*/
/* -------------------------------------------*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=submit] {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  background: none;
}

/* -------------------------------------------*/
@media all and (-ms-high-contrast: none) {
  html,
body {
    display: none;
  }
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  -ms-overflow-style: scrollbar;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

html {
  font-size: 62.5%;
}

@media screen and (min-width: 769px) and (max-width: 1900px) {
  html {
    font-size: 0.55vw;
  }
}
@media screen and (max-width: 768px) {
  html {
    font-size: 100%;
  }
}
body {
  height: 100%;
  font-size: 2rem;
  line-height: 1.5;
  font-family: "Noto Serif JP", "游明朝", "YuMincho", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  letter-spacing: 0.15em;
  overflow: hidden;
}

@media screen and (max-width: 960px) {
  body {
    letter-spacing: 1.5px;
  }
}
@media screen and (max-width: 768px) {
  body {
    font-size: 10px;
  }
}
section {
  position: relative;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

a:visited {
  color: unset;
}

picture {
  display: block;
  line-height: 0;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  vertical-align: middle;
  position: relative;
  outline: none;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

@media not all and (max-width: 768px) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}
/* -------------------------------------------*/
/*underline*/
/*arrow-fade*/
@keyframes arrow-fade {
  0% {
    transform: translateX(0);
  }
  49% {
    transform: translateX(15px);
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 1;
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0px);
  }
}
/* -------------------------------------------*/
/* -------------------------------------------*/
.l-footer {
  text-align: center;
  padding: 35px 0;
  background: #E7E4E0;
}
@media screen and (max-width: 768px) {
  .l-footer {
    padding: 10px 0;
  }
}

/* -------------------------------------------*/
.l-header {
  background: linear-gradient(to right, rgba(179, 159, 142, 0.3) 0%, #b39f8e 100%, #000 100%);
}
.l-header__inner {
  display: flex;
  justify-content: space-between;
  padding: 1.9rem 5rem;
}
@media screen and (max-width: 768px) {
  .l-header__inner {
    padding: 10px;
  }
}
.l-header__logo {
  display: flex;
  align-items: center;
}
.l-header__logo--img {
  width: 6.2rem;
}
@media screen and (max-width: 768px) {
  .l-header__logo--img {
    width: 30px;
  }
}
.l-header__logo--title {
  letter-spacing: 0.25em;
  line-height: 1.3;
  margin-left: 14px;
}
@media screen and (max-width: 768px) {
  .l-header__logo--title {
    margin-left: 10px;
  }
}
.l-header__nav {
  margin: auto 0;
}
@media screen and (max-width: 768px) {
  .l-header__nav {
    display: none;
  }
}
.l-header__list {
  display: flex;
}
.l-header__list-item {
  margin-right: 1rem;
  font-size: 2.2rem;
  padding: 0.8rem 2rem;
  transition: all 0.5s;
}
.l-header__list-item:hover {
  background: #4A4A4A;
  color: #fff;
  transition: all 0.5s;
}
.l-header__tab {
  overflow: hidden;
}
.l-header__tab--inner {
  transition: all 0.5s;
  position: absolute;
  z-index: 5;
  width: 100%;
  opacity: 0;
  visibility: hidden;
}
.l-header__tab--inner.active {
  transition: all 0.5s;
  opacity: 1;
  visibility: visible;
}

.l-header__hover {
  background: #4A4A4A;
  color: #fff;
  display: none;
  position: absolute;
  top: 10rem;
  left: 0;
  width: 100%;
  z-index: 2;
}
@media screen and (max-width: 768px) {
  .l-header__hover {
    position: relative;
    top: unset;
    color: #4A4A4A;
    display: block;
  }
  .l-header__hover:first-child, .l-header__hover:nth-child(3) {
    background: #EDEDED;
  }
  .l-header__hover:nth-child(2), .l-header__hover:nth-child(4), .l-header__hover:nth-child(5) {
    background: #fff;
  }
}
.l-header__hover--inner {
  display: flex;
}
@media screen and (max-width: 768px) {
  .l-header__hover--inner {
    display: block;
  }
}
.l-header__hover--left, .l-header__hover--right {
  padding: 3.5rem 4rem;
  width: 50%;
}
@media screen and (max-width: 768px) {
  .l-header__hover--left, .l-header__hover--right {
    padding: 10px 20px;
    width: 100%;
  }
}
.l-header__hover--title {
  font-size: 2.2rem;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .l-header__hover--title {
    font-size: 7px;
    margin-bottom: 15px;
  }
}
.l-header__hover--title h2 {
  font-weight: bold;
  border-bottom: 1px solid #fff;
  display: inline-block;
  letter-spacing: 0.3em;
}
@media screen and (max-width: 768px) {
  .l-header__hover--title h2 {
    border-color: #4A4A4A;
  }
}
.l-header__hover--list-wrap {
  display: flex;
}
.l-header__hover--list:first-child {
  margin-right: 50px;
}
@media screen and (max-width: 768px) {
  .l-header__hover--list:first-child {
    margin-right: 20px;
  }
}
.l-header__hover--list-item {
  margin-bottom: 15px;
  letter-spacing: 0.2em;
  font-size: 1.8rem;
}
@media screen and (max-width: 768px) {
  .l-header__hover--list-item {
    margin-bottom: 5px;
  }
}
.l-header__hover.active {
  display: block;
}

.l-header__hover--sns {
  padding: 40px 60px;
}
@media screen and (max-width: 768px) {
  .l-header__hover--sns {
    padding: 10px 20px;
  }
}
.l-header__hover--sns .l-header__hover--list-wrap {
  justify-content: space-between;
  max-width: 1735px;
}
@media screen and (max-width: 768px) {
  .l-header__hover--sns .l-header__hover--list-wrap {
    display: block;
  }
}
.l-header__hover--sns .l-header__hover--list:first-child {
  margin-right: 0;
}
.l-header__hover--sns li::before {
  content: "";
  display: inline-block;
  background-image: url(../img/index/icon-fb@2x.png);
  height: 50px;
  width: 50px;
  background-size: contain;
  vertical-align: middle;
  margin-right: 30px;
}
@media screen and (max-width: 768px) {
  .l-header__hover--sns li::before {
    height: 12px;
    width: 12px;
    vertical-align: sub;
    background-repeat: no-repeat;
    margin-right: 15px;
  }
}
.l-header__hover--blog li::before {
  content: "";
  display: inline-block;
  background-image: url(../img/index/icon-fb@2x.png);
  height: 50px;
  width: 50px;
  background-size: contain;
  vertical-align: middle;
  margin-right: 30px;
}
@media screen and (max-width: 768px) {
  .l-header__hover--blog li::before {
    height: 12px;
    width: 12px;
    vertical-align: sub;
    background-repeat: no-repeat;
    margin-right: 15px;
  }
}

.l-header__hover--list-item--insta::before {
  background-image: url(../img/index/icon-insta@2x.png) !important;
}
.l-header__hover--list-item--tw::before {
  background-image: url(../img/index/icon-tw@2x.png) !important;
}
.l-header__hover--list-item--yt::before {
  background-image: url(../img/index/icon-yt@2x.png) !important;
}

.hamburger {
  display: none;
}

@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
    width: 25px;
    height: 20px;
    position: relative;
    margin: 5px;
  }
  .hamburger span {
    background: #fff;
    width: 100%;
    height: 1px;
    position: absolute;
    transition: all 0.5s;
  }
  .hamburger span:first-child {
    top: 0;
  }
  .hamburger span:first-child.active {
    transform: translateX(0px) translateY(10px) rotate(45deg);
    background: #4A4A4A;
  }
  .hamburger span:nth-child(2) {
    top: 10px;
  }
  .hamburger span:nth-child(2).active {
    opacity: 0;
  }
  .hamburger span:nth-child(3) {
    bottom: 0;
  }
  .hamburger span:nth-child(3).active {
    transform: translateX(0px) translateY(-9px) rotate(-45deg);
    background: #4A4A4A;
  }
}
/* -------------------------------------------*/ /* -------------------------------------------*/
.c-btn {
  display: inline-block;
  width: 33rem;
  height: 7.5rem;
  line-height: 7.2rem;
  text-align: center;
  background: transparent;
  border: 1.5px solid #868080;
  border-radius: 50px;
  transition: all 0.5s;
}
@media screen and (max-width: 768px) {
  .c-btn {
    width: 140px;
    height: 32px;
    line-height: 30px;
    border: 1px solid #868080;
  }
}
.c-btn:hover {
  background: #868080;
  color: #fff !important;
}
.c-btn-colored {
  background: #868080;
  color: #fff !important;
}
.c-btn-colored:hover {
  background: #fff;
  color: #868080 !important;
  border-color: #fff;
}
.c-btn-transparent {
  background: transparent;
}
.c-btn-transparent:hover {
  background: rgba(255, 255, 255, 0.5);
  color: #fff !important;
  border-color: transparent;
}
.c-btn-white {
  background: #fff;
  color: #868080 !important;
  border-color: #fff;
}
.c-btn-white:hover {
  background: #868080;
  color: #fff !important;
}
.c-btn-slider {
  font-size: 2.7rem;
  letter-spacing: 10px;
  border: none !important;
}
@media screen and (max-width: 768px) {
  .c-btn-slider {
    font-size: 12px;
    letter-spacing: 5px;
  }
}
.c-btn-slider-01 {
  background: #868080;
  color: #fff !important;
}
.c-btn-slider-01:hover {
  background: #fff;
  color: #868080 !important;
}
.c-btn-slider-02 {
  background: #C9A4AA;
  color: #fff !important;
}
.c-btn-slider-02:hover {
  background: #fff;
  color: #868080 !important;
}
.c-btn-slider-03 {
  background: #A39081;
  color: #fff !important;
}
.c-btn-slider-03:hover {
  background: #fff;
  color: #868080 !important;
}
.c-btn-slider-04 {
  background: #A7B4AF;
  color: #fff !important;
}
.c-btn-slider-04:hover {
  background: #fff;
  color: #868080 !important;
}
.c-btn-mv {
  width: 20rem;
  height: 3rem;
  line-height: 3rem;
  background: #AF9886;
  color: #fff !important;
  border-radius: 0;
  border: unset;
}
@media screen and (max-width: 768px) {
  .c-btn-mv {
    width: 230px;
    height: 32px;
    line-height: 32px;
    font-size: 12px;
  }
}
.c-btn-header-contact {
  background: #EDEDED;
  color: #808080 !important;
  border: none;
  border-radius: 0;
  width: 67.5rem;
  height: 7rem;
  line-height: 7rem;
  display: block;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .c-btn-header-contact {
    width: 230px;
    height: 24px;
    line-height: 24px;
    display: block;
    margin: 0 auto;
  }
}

/* -------------------------------------------*/
@media screen and (max-width: 500px) {
  .pc {
    display: none !important;
  }
}
.sp {
  display: none !important;
}
@media screen and (max-width: 500px) {
  .sp {
    display: block !important;
  }
}

.tab {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .tab {
    display: block !important;
  }
}

@media screen and (max-width: 768px) {
  .tab-none {
    display: none !important;
  }
}

.p-index__mv {
  position: relative;
}
.p-index__mv--btn {
  position: absolute;
  top: 50%;
  right: -8.5rem;
  transform: translate(0, -50%) rotate(-90deg);
}
@media screen and (max-width: 768px) {
  .p-index__mv--btn {
    margin: 15px 0;
    position: unset;
    top: unset;
    right: unset;
    transform: unset;
    text-align: center;
  }
}

.p-index__slider--list-item {
  position: relative;
}
.p-index__slider--list-item img {
  width: 100%;
}
.p-index__slider--inner {
  width: 70rem;
  height: 79rem;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-image: url(../img/index/bg-mv.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 31rem;
  transition: all 0.5s;
  border-radius: 46% 46% 0 0;
}
.p-index__slider--inner.change {
  background-color: rgba(255, 255, 255, 0.2);
  background-blend-mode: lighten;
  color: #4A4A4A;
  transition: all 0.5s;
}
@media screen and (max-width: 768px) {
  .p-index__slider--inner {
    background-image: url(../img/index/bg-mv-sp.png);
    height: 270px;
    padding: 95px 5px 0;
    width: 200px;
    bottom: 0px;
  }
}
@media screen and (max-width: 500px) {
  .p-index__slider--inner {
    height: 330px;
    width: 250px;
    padding: 130px 10px 0;
    background-size: contain;
    bottom: 55px;
  }
}
.p-index__slider--btn {
  margin-top: 12.5rem;
}
@media screen and (max-width: 768px) {
  .p-index__slider--btn {
    margin-top: 55px;
  }
}

.p-index__slider--list-item--02 .p-index__slider--inner {
  background-image: url(../img/index/bg-mv-2.png);
}
.p-index__slider--list-item--03 .p-index__slider--inner {
  background-image: url(../img/index/bg-mv-3.png);
}
.p-index__slider--list-item--04 .p-index__slider--inner {
  background-image: url(../img/index/bg-mv-4.png);
}
.p-index__slider--list-item--04 .p-index__title--wrap-mv .p-index__title {
  color: #4A4A4A;
}
.p-index__slider--list-item--04 .p-index__title--wrap-mv .p-index__subtitle {
  color: #4A4A4A;
}

.slick-next {
  right: 50px !important;
  width: 5rem;
  height: 10rem;
}
@media screen and (max-width: 768px) {
  .slick-next {
    right: 10px !important;
    width: 15px;
    height: 30px;
  }
}

.slick-prev {
  left: 50px !important;
  width: 5rem;
  height: 10rem;
}
@media screen and (max-width: 768px) {
  .slick-prev {
    left: 10px !important;
    width: 15px;
    height: 30px;
  }
}

.slick-arrow {
  z-index: 2 !important;
}

.slick-arrow:before {
  content: "" !important;
}

.slick-arrow:before {
  content: "" !important;
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-next:before {
  background: url(../img/index/arrow-next.png) !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.slick-prev:before {
  background: url(../img/index/arrow-prev.png) !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.p-index__title {
  font-size: 3.8rem;
  letter-spacing: 0.3em;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .p-index__title {
    font-size: 18px;
  }
}
.p-index__title--wrap {
  text-align: center;
}
@media screen and (max-width: 768px) {
  .p-index__subtitle {
    font-size: 10px;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }
}

.p-index__title--wrap-mv .p-index__title {
  letter-spacing: 0.15em;
  color: #fff;
  transition: all 0.5s;
}
@media screen and (max-width: 768px) {
  .p-index__title--wrap-mv .p-index__title {
    font-size: 16px;
  }
}
.p-index__title--wrap-mv .p-index__subtitle {
  font-size: 2.7rem;
  color: #fff;
  transition: all 0.5s;
}
@media screen and (max-width: 768px) {
  .p-index__title--wrap-mv .p-index__subtitle {
    font-size: 12px;
  }
}

.p-index__slider--inner.change .p-index__title--wrap-mv .p-index__title {
  color: #4A4A4A;
  transition: all 0.5s;
}
.p-index__slider--inner.change .p-index__title--wrap-mv .p-index__subtitle {
  color: #4A4A4A;
  transition: all 0.5s;
}

.p-index__slider--list-item--04 .p-index__slider--inner.change .p-index__title--wrap-mv .p-index__title {
  color: #fff;
  transition: all 0.5s;
}
.p-index__slider--list-item--04 .p-index__slider--inner.change .p-index__title--wrap-mv .p-index__subtitle {
  color: #fff;
  transition: all 0.5s;
}

.p-index__content {
  margin-top: 7.5rem;
}
@media screen and (max-width: 768px) {
  .p-index__content {
    margin-top: 25px;
  }
}
.p-index__content--text {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 2.5rem;
  line-height: 1.4;
}
@media screen and (max-width: 768px) {
  .p-index__content--text {
    font-size: 12px;
    margin-bottom: 10px;
    padding: 0 10px;
    line-height: 1.25;
  }
}
@media screen and (max-width: 500px) {
  .p-index__content--text {
    text-align: start;
  }
}

.p-index__business--wrap {
  max-width: 1505px;
  margin: 6rem auto;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .p-index__business--wrap {
    flex-wrap: wrap;
    margin: 25px 0;
    padding: 0 10px;
    justify-content: space-around;
  }
  .p-index__business--wrap:after {
    content: "";
    display: block;
    width: 49%;
    max-width: 160px;
    height: 100%;
  }
}
.p-index__business--img {
  position: relative;
}
.p-index__business--img img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.5s;
  opacity: 0;
}
.p-index__business--img img:nth-child(2).show {
  opacity: 1;
  z-index: 1;
}
.p-index__business--block {
  max-width: 350px;
  width: 19%;
}
@media screen and (max-width: 768px) {
  .p-index__business--block {
    max-width: 160px;
    width: 49%;
    margin-bottom: 25px;
  }
}

.p-index__community .p-index__business--wrap {
  max-width: 1830px;
}

.p-index__sns--wrap {
  display: flex;
  justify-content: space-between;
  max-width: 1620px;
  margin: 5rem auto;
}
@media screen and (max-width: 768px) {
  .p-index__sns--wrap {
    flex-wrap: wrap;
    max-width: 300px;
    margin: 20px auto;
  }
  .p-index__sns--wrap::after {
    content: "";
    display: block;
    width: 90px;
    height: 95px;
  }
}
.p-index__sns--block {
  text-align: center;
  width: 15%;
}
@media screen and (max-width: 1440px) {
  .p-index__sns--block {
    width: 10%;
  }
}
@media screen and (max-width: 768px) {
  .p-index__sns--block {
    width: 30%;
  }
  .p-index__sns--block:not(:nth-child(-n+3)) {
    margin-top: 25px;
  }
}
.p-index__sns--img {
  position: relative;
}
.p-index__sns--img {
  position: relative;
}
@media screen and (max-width: 768px) {
  .p-index__sns--img {
    width: 40px;
    margin: 0 auto;
  }
}
.p-index__sns--img img {
  width: 50%;
}
.p-index__sns--img img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: -1;
  transition: all 0.5s;
  opacity: 0;
}
.p-index__sns--img img:nth-child(2).show {
  opacity: 1;
  z-index: 1;
}
.p-index__sns--content {
  margin-top: 1rem;
}
@media screen and (max-width: 768px) {
  .p-index__sns--content {
    font-size: 10px;
  }
}
.p-index__sns--title {
  font-size: 1.5rem;
}
@media screen and (max-width: 768px) {
  .p-index__sns--title {
    font-size: 10px;
  }
}

.p-index__voc {
  margin-top: 14.5rem;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .p-index__voc {
    margin-top: 40px;
  }
}
.p-index__voc--text {
  margin-bottom: 2rem;
}
@media screen and (max-width: 768px) {
  .p-index__voc--text {
    margin-bottom: 12px;
  }
}

.p-index__mv {
  position: relative;
}

.p-index__mission {
  padding: 15rem 2rem 14.5rem;
  background: #e7e4e0;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .p-index__mission {
    padding: 45px 0;
  }
}
.p-index__mission .p-index__content--text {
  text-align: center !important;
}
.p-index__mission--bg-text {
  position: absolute;
  font-size: 30rem;
  font-family: "Marcellus-Regular";
  color: #4A4A4A;
  top: 0;
  opacity: 0.05;
  line-height: 1;
  width: 100%;
  padding-left: 50px;
  padding-left: 5rem;
}
@media screen and (max-width: 768px) {
  .p-index__mission--bg-text {
    font-size: 110px;
    padding-left: 10px;
  }
}
.p-index__mission--bg-text p:nth-child(2) {
  text-align: end;
  position: relative;
  bottom: 45px;
  right: -50px;
}
@media screen and (max-width: 768px) {
  .p-index__mission--bg-text p:nth-child(2) {
    bottom: 20px;
    right: -60px;
  }
}

.p-index__work {
  padding: 15rem 2rem 20rem;
  background: linear-gradient(rgba(239, 205, 211, 0.09) 0%, rgba(239, 205, 211, 0.3) 100%);
  position: relative;
}
@media screen and (max-width: 768px) {
  .p-index__work {
    padding: 45px 0 35px;
  }
}
.p-index__work .p-index__business--wrap {
  width: 80%;
  margin: 0 auto;
  margin-top: 5rem;
}
@media screen and (max-width: 768px) {
  .p-index__work .p-index__business--wrap {
    width: 100%;
    margin-top: 20px;
    justify-content: space-around;
  }
}
.p-index__work .p-index__business--block {
  width: 24%;
}
@media screen and (max-width: 768px) {
  .p-index__work .p-index__business--block {
    width: 49%;
  }
}

.p-index__media {
  padding: 150px 20px;
  padding: 15rem 2rem;
  background: linear-gradient(rgba(190, 167, 150, 0.09) 0%, rgba(190, 167, 150, 0.3) 100%, #000 100%);
  position: relative;
}
@media screen and (max-width: 768px) {
  .p-index__media {
    padding: 50px 0 20px;
  }
}

.p-index__community {
  padding: 150px 20px 200px;
  padding: 15rem 2rem 20rem;
  background: linear-gradient(rgba(191, 204, 199, 0.09) 0%, rgba(191, 204, 199, 0.3) 100%);
  position: relative;
}
@media screen and (max-width: 768px) {
  .p-index__community {
    padding: 50px 0;
  }
}

.p-index__company {
  padding: 150px 20px 0;
  padding: 15rem 2rem 0;
  background: linear-gradient(to right, rgba(190, 167, 150, 0.3) 0%, rgba(190, 167, 150, 0.33) 15.03%, rgba(190, 167, 150, 0.4) 33.04%, rgba(190, 167, 150, 0.53) 52.58%, rgba(190, 167, 150, 0.71) 73.21%, rgba(190, 167, 150, 0.93) 94.48%, #bea796 100%, #000 100%);
}
@media screen and (max-width: 768px) {
  .p-index__company {
    padding: 50px 0 30px;
  }
}
.p-index__company--wrap {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .p-index__company--wrap {
    flex-direction: column;
  }
}
.p-index__company--block--merumaga {
  text-align: end;
  padding-top: 8rem;
  padding-right: 5rem;
  width: 30%;
}
@media screen and (max-width: 768px) {
  .p-index__company--block--merumaga {
    width: 100%;
    text-align: center;
    padding-top: 95px;
    padding-right: 0;
  }
}
.p-index__company--block--bg {
  background-image: url(../img/index/bg-company.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 58rem;
  text-align: center;
  margin: 0 4rem;
  transition: all 0.5s;
}
@media screen and (max-width: 768px) {
  .p-index__company--block--bg {
    width: 100%;
    margin: 0;
    background-image: url(../img/index/bg-company-sp.png);
    background-size: contain;
    background-position: center;
    padding-bottom: 50px;
    padding-top: 100px;
  }
}
.p-index__company--block--bg.show {
  transition: all 0.5s;
  background-image: url(../img/index/bg-company-hover.png);
}
@media screen and (max-width: 768px) {
  .p-index__company--block--bg.show {
    background-image: url(../img/index/bg-company-hover-sp.png);
  }
}
.p-index__company--block--bg.show .p-index__company--content {
  color: #fff;
}
.p-index__company--block--bg .p-index__company--inner {
  padding-top: 13rem;
  padding-bottom: 14rem;
}
.p-index__company--block--bg .p-index__company--content {
  margin-bottom: 4rem;
}
@media screen and (max-width: 768px) {
  .p-index__company--block--bg .p-index__company--content {
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 768px) {
  .p-index__company--block:nth-child(2) {
    padding-top: 25px;
    text-align: center;
  }
  .p-index__company--block:nth-child(2) .p-index__company--title {
    margin-bottom: 20px;
  }
}
.p-index__company--block--privacy {
  padding-top: 8rem;
  padding-left: 5rem;
  width: 30%;
}
@media screen and (max-width: 768px) {
  .p-index__company--block--privacy {
    width: 100%;
    padding-top: 45px;
    text-align: center;
    padding-left: 0;
  }
}
.p-index__company--content {
  margin-bottom: 3rem;
}
@media screen and (max-width: 768px) {
  .p-index__company--content {
    margin-bottom: 20px;
  }
}
.p-index__company--title {
  font-size: 2.7rem;
}
@media screen and (max-width: 768px) {
  .p-index__company--title {
    font-size: 14px;
  }
}
.p-index__company--list-item {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 768px) {
  .p-index__company--list-item {
    margin-bottom: 18px;
  }
}

.p-index__contact {
  padding: 15rem 2rem 20rem;
  text-align: center;
  background: #808080;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .p-index__contact {
    padding: 50px 0;
  }
}
.p-index__contact .p-index__content--text {
  font-size: 1.6rem;
  margin-bottom: 3.5rem;
}
@media screen and (max-width: 768px) {
  .p-index__contact .p-index__content--text {
    font-size: 10px;
    margin-bottom: 15px;
    text-align: center;
  }
}

/* -------------------------------------------*/
.u-bg--slash::before, .u-bg--slash::after {
  content: "";
  display: block;
  background-image: url(../img/index/bg-slash.png);
  background-size: cover;
  width: 100%;
  height: 60px;
  position: absolute;
}
@media screen and (max-width: 768px) {
  .u-bg--slash::before, .u-bg--slash::after {
    height: 20px;
    background-image: url(../img/index/bg-slash-sp.png);
  }
}
.u-bg--slash::before {
  top: 0;
  left: -6px;
}
@media screen and (max-width: 768px) {
  .u-bg--slash::before {
    left: -2px;
  }
}
.u-bg--slash::after {
  bottom: 0;
  left: 0;
}

.u-fade-in {
  opacity: 0;
  transition-duration: 1s;
  transition-property: opacity, transform;
}

.u-fade-in-up {
  transform: translate(0, 50px);
}

.u-fade-in-down {
  transform: translate(0, -50px);
}

.u-fade-in-left {
  transform: translate(-50px, 0);
}

.u-fade-in-right {
  transform: translate(50px, 0);
}

.u-scroll-in {
  opacity: 1;
  transform: translate(0, 0);
}

/* -------------------------------------------*/