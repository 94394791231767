@charset "utf-8";
@use "font";
@use "color";
@use "mixin"as mix;

/* -------------------------------------------*/
@media all and (-ms-high-contrast: none) {

  html,
  body {
    display: none;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  -ms-overflow-style: scrollbar;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  // scroll-behavior: smooth;
}

// レスポンシブ　追記
html {
  // font-size: 62.5%;
  font-size: 62.5%;
}

@media screen and (min-width: 769px) and (max-width: 1900px) {
  html {
    // font-size: 0.625vw;
    font-size: .55vw;
  }
}

@media screen and (max-width: 768px) {
  html{
    font-size: 100%;
  }
}

body {
  height: 100%;
  // font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
  font-family: font.$font-family-serif;
  letter-spacing: .15em;
  overflow: hidden;
  // color: color.$c-base-font;
}


@media screen and (max-width: 960px){
  body{
    letter-spacing: 1.5px;
    // font-size: 10px;
  }
}

@media screen and (max-width: 768px){
  body{
    font-size: 10px;
  }
}

section {
  position: relative;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

a:visited{
  color: unset;
}

picture {
  display: block;
  line-height: 0;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden; // Hide the overflow in IE
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  vertical-align: middle;
  position: relative;
  outline: none;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button,
input {
  overflow: visible; // Show the overflow in Edge
}

button,
select {
  text-transform: none; // Remove the inheritance of text transform in Firefox
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px; // 2. Correct the outline style in Safari.
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}


@include mix.mq {
  a[href^="tel:"] {
    pointer-events: none;
  }
}


