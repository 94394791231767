@charset "utf-8";
@use "mixin"as mix;

/* -------------------------------------------*/

:root {
  --header_height: 75px;
  --content_width: 100%;
  --side_weight: 195px;
  --side-between_space: 16px;
  --content_space--sm: 96px;
  --content_space--md: 128px;
  --sec_pd: 128px;
  --mv-news_width: 604px;
  --mv_over-height: 196px;
  --base_radius: 32px;
  --window_width: calc(100vw + calc(var(--side_weight) + var(--side-between_space)));

  --pages-left_space: 20.37vw;
  --pages-right_space: 14.8vw;

  @include mix.mq(xxl) {
    --content_space--sm: 128px;
    --content_space--md: 180px;
    --side-between_space: 64px;
    --mv-news_width: 650px;
  }

  @include mix.mq(xl, max) {
    --content_space--sm: 4vw;
    --content_space--md: 9.2vw;
    --sec_pd: 86px;
    --mv-news_width: 38.88vw;
  }

  @include mix.mq(lg, max) {
    --content_width: 1000px;
    --header_height: 64px;
    --side_weight: 65px;
    --content_space--md: 76px;
    --mv-news_width: 82.4vw;
    --mv_over-height: 53px;
    --base_radius: 16px;
    --content-both_space: 15px;
    --window_width: 100vw;

    --pages-left_space: 19.73vw;
    --pages-right_space: 0;
  }

  @include mix.mq(md, max) {
    --content_width: 580px;
    --side_weight: 60px;
    --content_space--md: 64px;
    --sec_pd: 32px;
  }
}

$z-index: (header: 10,
  modal: 20);
