@charset "utf-8";
@use "mixin"as mix;
/* -------------------------------------------*/

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @return if($index,
    str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace),
    $string);
}

@function hexToUrl($color) {
  $newcolor: str-replace($color, '#', '%23');
  @return $newcolor;
}

// icon
@mixin icon_arrow {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 12.3'%3E%3Cpath d='M0.5,12.3c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.2-0.1-0.5,0.2-0.7l8.4-5.2L1,1.4L1,7.8c0,0.3-0.2,0.5-0.5,0.5 c0,0,0,0,0,0C0.3,8.3,0,8.1,0,7.8L0,0.5C0,0.3,0.1,0.1,0.2,0C0.4-0.1,0.6,0,0.7,0l9.1,5.7C9.9,5.8,10,6,10,6.1S9.9,6.5,9.8,6.6 l-9.1,5.7C0.7,12.3,0.6,12.3,0.5,12.3z'/%3E%3C/svg%3E");
  mask-position: center center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

@mixin icon_mail {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 26.5'%3E%3Cpath d='M36,5.4v9.4l0,6.7l-8-8L36,5.4z M20.6,17.1l3.7-3.7L36,1.8c-0.2-1-1.1-1.8-2.1-1.8H2.2C1.1,0,0.2,0.8,0,1.8 l15.3,15.3C16.8,18.6,19.2,18.6,20.6,17.1z M8,13.4l-8-8v16l3.3-3.3L8,13.4z M22.5,19L22.5,19c-2.5,2.5-6.5,2.5-8.9,0l-3.7-3.7 L0.1,25c0.3,0.9,1.1,1.4,2,1.4h31.7c0.9,0,1.7-0.6,2-1.4l-9.7-9.7L22.5,19z'/%3E%3C/svg%3E");
  mask-position: center center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

@mixin icon_twitter {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.3 13.3'%3E%3Cpath d='M14.6,3.3c0,0.1,0,0.3,0,0.4c0,1.8-0.5,3.6-1.5,5.2c-2.8,4.4-8.7,5.7-13.1,2.8c0.3,0,0.5,0,0.8,0 c1.5,0,3-0.5,4.1-1.4c-1.4,0-2.7-1-3.1-2.3c0.2,0,0.4,0.1,0.6,0.1c0.3,0,0.6,0,0.9-0.1C1.8,7.7,0.6,6.3,0.6,4.7v0 c0.5,0.3,1,0.4,1.5,0.4c-1.5-1-1.9-2.9-1-4.5C2.8,2.7,5.3,4,8,4.1C8,3.9,7.9,3.6,7.9,3.3c0-0.9,0.4-1.8,1.1-2.4 c1.3-1.3,3.5-1.2,4.7,0.2c0.7-0.1,1.5-0.4,2.1-0.8c-0.2,0.8-0.8,1.4-1.5,1.9C15,2,15.7,1.8,16.3,1.6C15.9,2.2,15.3,2.8,14.6,3.3z'/%3E%3C/svg%3E");
  mask-position: center center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

@mixin icon_youtube {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17.5 12.2'%3E%3Cpath d='M17.1,1.9c-0.2-0.8-0.8-1.3-1.5-1.5C13.3,0.1,11,0,8.7,0C6.5,0,4.2,0.1,1.9,0.4C1.2,0.6,0.6,1.2,0.4,1.9 c-0.5,2.8-0.5,5.6,0,8.4c0.2,0.8,0.8,1.3,1.5,1.5c2.3,0.3,4.5,0.4,6.8,0.4c2.3,0,4.6-0.1,6.8-0.4c0.8-0.2,1.3-0.8,1.5-1.5 c0.3-1.4,0.4-2.8,0.4-4.2C17.5,4.7,17.4,3.3,17.1,1.9z M7,8.7V3.5l4.5,2.6L7,8.7z'/%3E%3C/svg%3E");
  mask-position: center center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

@mixin icon_tel {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath  d='M15.2,11.1l-2-1.4c-0.6-0.5-1.5-0.5-2.1,0l-0.8,0.8c-0.1,0.1-0.4,0.1-0.8-0.1l-0.1,0C8.6,10,7.8,9.5,7.2,8.8 l0,0C6.5,8.2,6,7.4,5.6,6.6l0-0.1C5.4,6,5.4,5.8,5.5,5.6l0.8-0.8c0.6-0.6,0.6-1.5,0-2.1L5,0.8l0,0C4.6,0.4,4.1,0.1,3.5,0 C3,0,2.5,0.2,2.1,0.6l-1,1C0,2.9-0.3,4.7,0.2,6.4c0.6,2.3,1.8,4.4,3.5,6l0,0c1.6,1.7,3.7,2.9,6,3.4c0.5,0.1,1,0.2,1.6,0.2 c1.2,0.1,2.3-0.4,3.2-1.2l1-1c0.4-0.4,0.6-0.9,0.6-1.4C15.9,11.9,15.6,11.4,15.2,11.1'/%3E%3C/svg%3E");
  mask-position: center center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

@mixin back_trapezoid($color) {
  background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 1566 752.158'%3E%3Cpath fill='#{hexToUrl($color)}' d='M0,0H1566V486L0,752.158Z'/%3E%3C/svg%3E");
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  @include mix.mq(lg, max) {
    background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 375 406.053'%3E%3Cpath fill='#{hexToUrl($color)}' d='M0,0H375V342.318L0,406.053Z'/%3E%3C/svg%3E");
  }
}

@mixin page_top {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.3 14'%3E%3Cpath  d='M15.6,13.8c-0.2,0-0.3-0.1-0.4-0.2l-7.1-12L1.5,12.8l8.9-0.1h0c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5 l-9.8,0.1c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.2-0.1-0.3,0-0.5L7.7,0.4c0.2-0.3,0.7-0.3,0.9,0l7.5,12.7c0.1,0.2,0.1,0.5-0.2,0.7 L15.6,13.8z'/%3E%3C/svg%3E");
  mask-position: center center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

@mixin icon_cta {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 15.3'%3E%3Cpath  d='M9,0C4,0,0,3,0,6.6c0,3.3,3.3,6,7.7,6.5c-0.1,0.5-0.4,0.9-0.7,1.3C6.8,14.7,6.8,15,7,15.2 c0.1,0.1,0.2,0.1,0.3,0.1l0.3,0c1.7,0,3.2-0.9,4-2.4c3.8-0.8,6.5-3.4,6.5-6.3C18,3,14,0,9,0 M11.1,12c-0.2,0-0.3,0.1-0.4,0.3 c-0.5,1-1.4,1.7-2.4,1.9c0.2-0.5,0.4-1,0.4-1.5c0-0.3-0.2-0.5-0.4-0.6l0,0C4.2,11.9,1,9.5,1,6.6C1,3.5,4.6,1,9,1s8,2.5,8,5.6 C17,9.1,14.6,11.3,11.1,12 M5.2,5.7c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9C4.7,7.4,4.3,7,4.3,6.5C4.3,6.1,4.7,5.7,5.2,5.7 M9,5.7c0.5,0,0.9,0.4,0.9,0.9C9.9,7,9.5,7.4,9,7.4C8.5,7.4,8.1,7,8.1,6.5C8.1,6.1,8.5,5.7,9,5.7 M12.8,5.7c0.5,0,0.9,0.4,0.9,0.9 c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9S12.3,5.7,12.8,5.7'/%3E%3C/svg%3E");
  mask-position: center center;
  mask-size: contain;
  mask-repeat: no-repeat;
}
