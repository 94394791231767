@charset "utf-8";

/* -------------------------------------------*/
$font-family-sans:'Noto Sans JP',
"ヒラギノ角ゴ ProN W3",
"Hiragino Kaku Gothic ProN",
"游ゴシック",
YuGothic,
"メイリオ",
Meiryo,
sans-serif;

$font-family-serif: 'Noto Serif JP',
"游明朝",
"YuMincho",
"ヒラギノ明朝 ProN W6",
"Hiragino Mincho ProN",
"Hiragino Mincho Pro",
"ＭＳ 明朝",
serif;

$raleway:'Raleway',
sans-serif;
