@charset "utf-8";
@use "../foundation"as *;


/* -------------------------------------------*/

@include mq(sm, max) {
  .pc{
    display: none !important;
  }
}

.sp{
  display: none !important;
  @include mq(sm, max) {
    display: block !important;
  }
}

.tab{
  display: none !important;
  @include mq(md, max) {
    display: block !important;
  }
}

.tab-none{
  @include mq(md, max) {
    display: none !important;
  }
}

.p-index__mv{
  // height: 980px;
  position: relative;

  &--btn{
    position: absolute;
    top: 50%;
    right: -8.5rem;
    transform: translate(0, -50%) rotate(-90deg);
    @include mq(md, max) {
      margin: 15px 0;
      position: unset;
      top: unset;
      right: unset;
      transform: unset;
      text-align: center;
    }
  }
}


// 各パーツ

.p-index{
  &__slider{
    &--list-item{
      position: relative;
      img{
        width: 100%;
      }
    }
    &--inner{
      // width: 700px;
      width: 70rem;
      // height: 790px;
      height: 79rem;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      background-image: url(../img/index/bg-mv.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      // padding-top: 310px;
      padding-top: 31rem;
      transition: all .5s;
      border-radius: 46% 46% 0 0;
      &.change{
        background-color:rgba(255,255,255,0.2);
        background-blend-mode:lighten;
        color: #4A4A4A;
        transition: all .5s;
      }
      @include mq(md, max) {
        background-image: url(../img/index/bg-mv-sp.png);
        height: 270px;
        padding: 95px 5px 0;
        width: 200px;
        bottom: 0px;
      }
      @include mq(sm, max) {
        height: 330px;
        width: 250px;
        padding: 130px 10px 0;
        background-size: contain;
        bottom: 55px;
      }
    }
    &--btn{
      // margin-top: 125px;
      margin-top: 12.5rem;
      @include mq(md, max) {
        margin-top: 55px;
      }
    }
  }
}

.p-index__slider--list-item{
  &--02{
    .p-index__slider--inner{
      background-image: url(../img/index/bg-mv-2.png);
    }
  }
  &--03{
    .p-index__slider--inner{
      background-image: url(../img/index/bg-mv-3.png);
    }
  }
  &--04{
    .p-index__slider--inner{
      background-image: url(../img/index/bg-mv-4.png);
    }
    .p-index__title--wrap-mv .p-index__title{
      color: #4A4A4A;
    }
    .p-index__title--wrap-mv .p-index__subtitle{
      color: #4A4A4A;
    }
  }
}

.slick-next{
   right:50px!important; 
  //  width: 50px;
   width: 5rem;
  //  height: 100px;
   height: 10rem;
    @include mq(md, max) {
      right:10px!important; 
      width: 15px;
      height: 30px;
    }
}

.slick-prev{
   left:50px!important; 
   //  width: 50px;
    width: 5rem;
   //  height: 100px;
    height: 10rem;
    @include mq(md, max) {
      left:10px!important; 
      width: 15px;
      height: 30px;
    }
}

.slick-arrow{
   z-index:2!important; 
}

.slick-arrow:before{
  content:""!important;
}

.slick-arrow:before{
  content:""!important;
  width: 100%!important;
  height: 100%!important;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-next:before{
  background: url(../img/index/arrow-next.png)!important;
  background-size: contain!important;
  background-repeat: no-repeat !important;
}

.slick-prev:before{
  background: url(../img/index/arrow-prev.png)!important;
  background-size: contain!important;
  background-repeat: no-repeat !important;
}



.p-index{
  &__title{
    // font-size: 42px;
    font-size: 3.8rem;
    letter-spacing: 0.3em;
    line-height: 1;
    @include mq(md, max) {
      font-size: 18px;
    }
    &--wrap{
      text-align: center;
    }
  }
  &__subtitle{
    @include mq(md, max) {
      font-size: 10px;
      letter-spacing: 1px;
      margin-bottom: 5px;
    }
  }
}

.p-index__title--wrap-mv{
  .p-index__title{
    letter-spacing: .15em;
    color: #fff;
    transition: all .5s;
    @include mq(md, max) {
      font-size: 16px;
    }
  }
  .p-index__subtitle{
    // font-size: 27px;
    font-size: 2.7rem;
    color: #fff;
    transition: all .5s;
    @include mq(md, max) {
      font-size: 12px;
    }
  }
}

.p-index__slider--inner.change{
  .p-index__title--wrap-mv{
    .p-index__title{
      color: #4A4A4A;
      transition: all .5s;
    }
    .p-index__subtitle{
      color: #4A4A4A;
      transition: all .5s;
    }
  }
}


.p-index__slider--list-item--04{
  .p-index__slider--inner.change{
    .p-index__title--wrap-mv{
      .p-index__title{
        color: #fff;
        transition: all .5s;
      }
      .p-index__subtitle{
        color: #fff;
        transition: all .5s;
      }
    }
  }
}


.p-index{
  &__content{
    // margin-top: 75px;
    margin-top: 7.5rem;
    @include mq(md, max) {
      margin-top: 25px;
    }
    &--text{
      text-align: center;
      // font-size: 27px;
      font-size: 2.2rem;
      // margin-bottom: 25px;
      margin-bottom: 2.5rem;
      line-height: 1.4;
      @include mq(md, max) {
        font-size: 12px;
        margin-bottom: 10px;
        padding: 0 10px;
        line-height: 1.25;
      }
      @include mq(sm, max) {
        text-align: start;
      }
    }

  }
}

.p-index{
  &__business{
    &--wrap{
      max-width: 1505px;
      // margin: 60px auto;
      margin: 6rem auto;
      display: flex;
      justify-content: space-between;
      @include mq(md, max) {
        flex-wrap: wrap;
        margin: 25px 0;
        padding: 0 10px;
        justify-content: space-around;
        &:after{
          content: "";
          display: block;
          width: 49%;
          max-width: 160px;
          height: 100%;
        }
      }
    }
    &--img{
      position: relative;
      img:nth-child(2){
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all .5s;
        opacity: 0;
        &.show{
          opacity: 1;
          z-index: 1;
        }
      }
    }
    &--block{
      max-width: 350px;
      width: 19%;
      @include mq(md, max) {
        max-width: 160px;
        width: 49%;
        margin-bottom: 25px;
      }
    }
  }
}

.p-index__community{
  .p-index__business--wrap{
    max-width: 1830px;
  }
}


.p-index{
  &__sns{
    &--wrap{
      display: flex;
      justify-content: space-between;
      max-width: 1620px;
      // margin: 50px auto;
      margin: 5rem auto;
      @include mq(md, max) {
        flex-wrap: wrap;
        max-width: 300px;
        margin: 20px auto;
        &::after{
          content: "";
          display: block;
          width: 90px;
          height: 95px;
        }
      }
    }
    &--block{
      text-align: center;
      width: 15%;
      @include mq(lg, max) {
        width: 10%;
      }
      @include mq(md, max) {
        width: 30%;
        &:not(:nth-child(-n+3)){
          margin-top: 25px;
        }
      }
    }
    &--img{
      position: relative;
    }
    &--img{
      position: relative;
      @include mq(md, max) {
        width: 40px;
        margin: 0 auto;
      }
      img{
        width: 50%;
      }
      img:nth-child(2){
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: -1;
        transition: all .5s;
        opacity: 0;
        &.show{
          opacity: 1;
          z-index: 1;
        }
      }
    }
    &--content{
      // margin-top: 10px;
      margin-top: 1rem;
      @include mq(md, max) {
        font-size: 10px;
      }
    }
    &--title{
      font-size: 1.5rem;
      @include mq(md, max) {
        font-size: 10px;
      }
    }
  }
}

.p-index{
  &__voc{
    // margin-top: 115px;
    margin-top: 14.5rem;
    text-align: center;
    @include mq(md, max) {
      margin-top: 40px;
    }
    &--text{
      // margin-bottom: 20px;
      margin-bottom: 2rem;
      @include mq(md, max) {
        margin-bottom: 12px;
      }
    }
  }
}


// セクション
.p-index__mv{
  position: relative;
}

.p-index__mission{
  // padding: 150px 20px 145px;
  padding: 15rem 2rem 14.5rem;
  background: #e7e4e0;
  position: relative;
  overflow: hidden;
  @include mq(md, max) {
    padding: 45px 0;
  }
  .p-index__content--text{
    text-align: center !important;
  }
  &--bg-text{
    position: absolute;
    // font-size: 300px;
    font-size: 30rem;
    font-family: "Marcellus-Regular";
    color: #4A4A4A;
    top: 0;
    opacity: .05;
    line-height: 1;
    width: 100%;
    padding-left: 50px;
    padding-left: 5rem;
    @include mq(md, max) {
      font-size: 110px;
      padding-left: 10px;
    }
    p{
      &:nth-child(2){
        text-align: end;
        position: relative;
        bottom: 45px;
        right: -50px;
        @include mq(md, max) {
          bottom: 20px;
          right: -60px;
        }
      }
    }
  }
}

.p-index__work{
  // padding: 150px 20px;
  padding: 15rem 2rem 20rem;
  background: linear-gradient(rgba(239, 205, 211, 0.09) 0%, rgba(#efcdd3, .3) 100%);
  position: relative;
  @include mq(md, max) {
    padding: 45px 0 35px;
  }
  .p-index__business--wrap{
    width: 80%;
    margin: 0 auto;
    margin-top: 5rem;
    @include mq(md, max) {
      width: 100%;
      margin-top: 20px;
      justify-content: space-around;
    }
  }
  .p-index__business--block{
    width: 24%;
    @include mq(md, max) {
      width: 49%;
    }
  }
}

.p-index__media{
  padding: 150px 20px;
  padding: 15rem 2rem;
  background: linear-gradient(rgba(190, 167, 150, 0.09) 0%, rgba(#bea796, .3) 100%, #000 100%);
  position: relative;
  @include mq(md, max) {
    padding: 50px 0 20px;
  }
}

.p-index__community{
  padding: 150px 20px 200px;
  padding: 15rem 2rem 20rem;
  background: linear-gradient(rgba(191, 204, 199, 0.09) 0%, rgba(#bfccc7, .3) 100%);
  position: relative;
  @include mq(md, max) {
    padding: 50px 0;
  }
}

.p-index__company{
  padding: 150px 20px 0;
  padding: 15rem 2rem 0;
  background: linear-gradient(to right, rgba(190, 167, 150, 0.3) 0%, rgba(190, 167, 150, 0.33) 15.03%, rgba(190, 167, 150, 0.4) 33.04%, rgba(190, 167, 150, 0.53) 52.58%, rgba(190, 167, 150, 0.71) 73.21%, rgba(190, 167, 150, 0.93) 94.48%, #bea796 100%, #000 100%);
  @include mq(md, max) {
    padding: 50px 0 30px;
  }
  &--wrap{
    display: flex;
    justify-content: center;
    @include mq(md, max) {
      flex-direction: column;
    }
  }
  &--block{
    &--merumaga{
      text-align: end;
      // padding-top: 80px;
      padding-top: 8rem;
      padding-right: 5rem;
      width: 30%;
      @include mq(md, max) {
        width: 100%;
        text-align: center;
        padding-top: 95px;
        padding-right: 0;
      }
    }
    &--bg{
      background-image: url(../img/index/bg-company.png);
      background-repeat: no-repeat;
      background-size: cover;
      // width: 580px;
      width: 58rem;
      text-align: center;
      // margin: 0 100px;
      margin: 0 4rem;
      transition: all .5s;
      @include mq(md, max) {
        width: 100%;
        margin: 0;
        background-image: url(../img/index/bg-company-sp.png);
        background-size: contain;
        background-position: center;
        padding-bottom: 50px;
        padding-top: 100px;
      }
      &.show{
        transition: all .5s;
        background-image: url(../img/index/bg-company-hover.png);
        @include mq(md, max) {
          background-image: url(../img/index/bg-company-hover-sp.png);
        }
        .p-index__company--content{
          color: #fff;
        }
      }
      .p-index__company--inner{
        // padding-top: 130px;
        padding-top: 13rem;
        // padding-bottom: 140px;
        padding-bottom: 14rem;

      }
      .p-index__company--content{
        // margin-bottom: 40px;
        margin-bottom: 4rem;
        @include mq(md, max) {
          margin-bottom: 70px;
        }
      }
    }
    &:nth-child(2){
      @include mq(md, max) {
        padding-top: 25px;
        text-align: center;
        .p-index__company--title{
          margin-bottom: 20px;
        }
      }
    }
    &--privacy{
      // padding-top: 80px;
      padding-top: 8rem;
      padding-left: 5rem;
      width: 30%;
      @include mq(md, max) {
        // padding-top: 40px;
        // text-align: center;
        width: 100%;
        padding-top: 45px;
        text-align: center;
        padding-left: 0;
      }
    }
  }
  &--content{
    // margin-bottom: 30px;
    margin-bottom: 3rem;
    @include mq(md, max) {
      margin-bottom: 20px;
    }
  }
  &--title{
    // font-size: 27px;
    font-size: 2.7rem;
    @include mq(md, max) {
      font-size: 14px;
    }
    
  }
  &--list-item{
    // margin-bottom: 25px;
    margin-bottom: 2.5rem;
    @include mq(md, max) {
      margin-bottom: 18px;
    }
  }
}

.p-index__contact{
  // padding: 150px 20px 200px;
  padding: 15rem 2rem 20rem;
  text-align: center;
  background: #808080;
  color: #fff;
  @include mq(md, max) {
    padding: 50px 0;
  }
  .p-index__content--text{
    // font-size: 16px;
    font-size: 1.6rem;
    // margin-bottom: 35px;
    margin-bottom: 3.5rem;
    @include mq(md, max) {
      font-size: 10px;
      margin-bottom: 15px;
      text-align: center;
    }
  }
}
