@charset "utf-8";
@use "../foundation"as *;

/* -------------------------------------------*/
.u-bg--slash{
  &::before,
  &::after{
    content: "";
    display: block;
    background-image: url(../img/index/bg-slash.png);
    background-size: cover;
    width: 100%;
    height: 60px;
    position: absolute;
    @include mq(md, max) {
      height: 20px;
      background-image: url(../img/index/bg-slash-sp.png);
    }
  }
  &::before{
    top: 0;
    left: -6px;
    @include mq(md, max) {
      left: -2px;
    }
  }
  &::after{
    bottom: 0;
    left: 0;
  }

}

.u-fade-in {
  opacity: 0;
  transition-duration: 1s;
  transition-property: opacity, transform;
}


.u-fade-in-up {
  transform: translate(0, 50px);
}

.u-fade-in-down {
  transform: translate(0, -50px);
}

.u-fade-in-left {
  transform: translate(-50px, 0);
}

.u-fade-in-right {
  transform: translate(50px, 0);
}

.u-scroll-in {
  opacity: 1;
  transform: translate(0, 0);
}




// .u-dn--lg-max {
//   @include mq(lg, max) {
//     display: none;
//   }
// }

// .u-dn--lg-min {
//   @include mq(lg) {
//     display: none;
//   }
// }

// .u-dn--md-min {
//   @include mq(md) {
//     display: none;
//   }
// }

// .u-dn--md-max {
//   @include mq(md, max) {
//     display: none;
//   }
// }

// .u-c--c-white {
//   color: $c-white;
// }

// .u-c--c-main {
//   color: $c-main;
// }

// .u-c--c-blue {
//   color: $c-blue;
// }

// .u-c--b-white {
//   color: $c-white;
// }

// .u-z-1 {
//   z-index: -1;
// }

// .u-z1 {
//   z-index: 1;
// }

// .u-z10 {
//   z-index: 10;
// }

// .u-z50 {
//   z-index: 50;
// }

// .u-z100 {
//   z-index: 100;
// }

// .u-z999 {
//   z-index: 999;
// }
