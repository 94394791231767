@charset "utf-8";
@use "../foundation"as *;

/* -------------------------------------------*/


.l-footer{
  text-align: center;
  padding: 35px 0;
  background: #E7E4E0;
  @include mq(md, max) {
    padding: 10px 0;
  }
}